<template>
  <div class="p-16 bg-tl-green-100 rounded-40px">
    <div class="flex flex-row">
      <div class="flex flex-col w-2/3">
        <div class="flex flex-row text-green-400 text-sm">{{ meeting }}</div>
        <div
          class="flex flex-row text-white text-4xl font-medium font-['Inter'] leading-[46px] mt-4"
        >
          How does the integration of AI-powered tools reshape the role of assessment methodologies
          in higher education?
        </div>
        <div class="flex flex-row mt-4">
          <div
            id="main-content"
            class="flex flex-row shadow-md text-white/50 overflow-hidden"
            :class="{ 'max-h-10': toggleText }"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id est laborum.
          </div>
        </div>
        <!-- Read More/Hide Button -->
        <div class="flex flex-row mt-1">
          <div class="flex flex-col">
            <button
              id="toggle-btn"
              class="text-white/50 hover:text-white/50 font-semibold focus:outline-none text-decoration-line: underline"
              @click="toggleText = !toggleText"
            >
              {{ toggleText ? 'Read More' : 'Hide' }}
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-1/12"></div>
      <div class="flex flex-col w-3/12">
        <div class="flex flex-row text-white">Meeting Time and Date</div>
        <div class="flex flex-row text-white pb-5">
          Participants ({{ durationChartData.length }})
        </div>
        <div class="flex flex-row">
          <div class="px-2.5 py-1.5 bg-tl-green-400 font-semibold rounded-md">Online Meeting</div>
        </div>
      </div>
    </div>
    <div class="flex flex-row mt-10 p-10 bg-tl-green-200 rounded-20px">
      <div class="flex flex-col w-screen">
        <div class="flex flex-row">
          <div class="px-5 py-2.5 border border-green-400 rounded-3xl text-2xl text-white">
            Action Items
          </div>
        </div>
        <div class="flex flex-row mt-4">
          <div
            id="action-item-content"
            class="text-white text-lg overflow-hidden"
            :class="{ 'max-h-10': actionItemToggleText }"
            v-html="feedback"
          ></div>
        </div>
        <!-- Read More/Hide Button -->
        <div class="flex flex-row mt-5">
          <button
            id="toggle-btn"
            class="text-tl-green-400 hover:text-tl-green-400/50 font-semibold focus:outline-none text-decoration-line: underline"
            @click="actionItemToggleText = !actionItemToggleText"
          >
            {{ actionItemToggleText ? 'Read More' : 'Hide' }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="flex flex-col w-1/2 mt-10 p-10 mr-2 bg-tl-green-200 rounded-20px">
        <div class="flex flex-row">
          <div class="px-5 py-2.5 border border-green-400 rounded-3xl text-2xl text-white">
            Speaking Time
          </div>
        </div>
        <div class="flex flex-row mt-4" id="durPieChart">
          <PieChart class="h-24" :data="durationChartData" :colours="legendColours" />
        </div>
      </div>
      <div class="flex flex-col w-1/2 mt-10 p-10 ml-2 bg-tl-green-200 rounded-20px">
        <div class="flex flex-row">
          <div class="px-5 py-2.5 border border-green-400 rounded-3xl text-2xl text-white">
            Speaking Turn
          </div>
        </div>
        <div class="flex flex-row mt-4" id="turnPieChart">
          <PieChart class="h-24" :data="turnsChartData" :colours="legendColours" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, type Ref, watch, toRefs } from 'vue'
import { useTeamStore } from '@/composition/stores/teamStore'
import { useMeetingStore } from '@/composition/stores/meetingStore'
import PieChart from '@/components/charts/PieChart.vue'
import type { PieData } from '@/components/charts/PieChart.vue'

const teamStore = useTeamStore()
const meetingStore = useMeetingStore()
const feedback = ref('')
const durationChartData: Ref<PieData[]> = ref([])
const turnsChartData: Ref<PieData[]> = ref([])
const legendColours: Record<string, string> = {
  S1: '#00FF00',
  S2: '#FF0000',
  S3: '#FF00FF'
}

const toggleText = ref(true)
const actionItemToggleText = ref(true)
const props = defineProps<{ selectedTab: string }>()
const { selectedTab } = toRefs(props)
const meeting = selectedTab.value
  .slice(0, selectedTab.value.indexOf('GRP'))
  .replaceAll('_', ' ')
  .toUpperCase()

watch(selectedTab, () => {
  teamStore.getters.meetingFeedback.value.forEach((item) => {
    if (item.filename === selectedTab.value.replace('_T', '')) {
      feedback.value = item.filefeedback
    }
  })

  const selectedMeeting = meetingStore.getters.speakingInfo.value.find(
    (item) => item.filename == selectedTab.value
  )
  if (selectedMeeting) {
    durationChartData.value = []
    turnsChartData.value = []
    selectedMeeting.speakinginfo.map((item) => {
      durationChartData.value.push({ name: item.speaker, value: item.duration })
      turnsChartData.value.push({ name: item.speaker, value: item.count })
    })
  }
})

/*const getPieChartDuration = (fileName: string) => {
  let selectedMeeting = meetingStore.getters.speakingInfo.value.find(
    (item) => item.filename == fileName
  ) as MeetingInfo
  selectedMeeting.speakinginfo.map((item) => {
    durationChartData.push({ name: item.speaker, value: item.duration })
    turnsChartData.push({ name: item.speaker, value: item.count })
  })
}
onMounted(() => getPieChartDuration(selectedTab))*/
</script>
