import {
  serverBaseUrl,
  HttpException,
  XHR_CONTENT_TYPE,
  XHR_REQUEST_TYPE,
  ErrorCode
} from '../constants.js'

import type { APIRequestPayload, APIResponse, IUser } from '@/models/main.js'
import type { DataCSVRow, NursingGroup } from '@frontend'

import { DialogMessageType } from '@/interfaces.js'
import useDialogStore from '@/composition/dialog.js'
import { parseWithDate } from '@/utilities.js'
import type { Segment } from '@database'
import type { ModuleType } from '@models'

interface ServiceInterface {
  base: {
    getEncodedParams: (query: Record<string, string>) => string
    apiRequest: <T>(
      {
        errorCode,
        route,
        method,
        query, // A dictionary containing values to convert to a URL query string (e.g. ?a=yyy&b=zzz)
        body, // Can be FormData, JSON, text, or Object (unknown type). Use contentType to specify which
        headers,
        credentials,
        contentType, // Chosen using XHR_CONTENT_TYPE enumerator. Defaults to 'application/json'
        baseURL,
        requiresFeedback
      }: APIRequestPayload,
      callback?: () => void
    ) => Promise<APIResponse<T>>
  }
  request: {
    getGroup: (callback?: () => void) => Promise<APIResponse<Response>>
    getGroups: (callback?: () => void) => Promise<APIResponse<NursingGroup[]>>
    getDataCSV: (callback?: () => void) => Promise<APIResponse<DataCSVRow>>
    getGroupByRow: (rowId: string, callback?: () => void) => Promise<APIResponse<Response>>
    getSessionByRow: (crowId: string, callback?: () => void) => Promise<APIResponse<Response>>
    fetchUserDetails: (callback?: () => void) => Promise<APIResponse<IUser>>
    test: (callback?: () => void) => Promise<APIResponse<object>>
    logout: () => Promise<void>
    updateSegments: (
      sessionId: string,
      segments: Segment[],
      callback?: () => void
    ) => Promise<APIResponse<Response>>
  }
  pipeline: {
    createSession: (groupName: string) => Promise<APIResponse<object>>
    runPipeline: (sessionId: string, modules?: ModuleType[]) => Promise<APIResponse<object>>
  }
}

function useApiService(): ServiceInterface {
  const dialogStore = useDialogStore()

  const apiBaseURL = '/api'

  const getSuccessTag = () => {
    switch (navigator.language) {
      case 'nb-NO':
      case 'nn-NO':
      case 'no':
        return 'Vellykket!'
      default:
        return 'Success!'
    }
  }

  const base = {
    getEncodedParams: (query: Record<string, string>): string => {
      // Encode query and body
      let qs = ''
      const queryKeys = query ? Reflect.ownKeys(query).map((k) => k.toString()) : []
      if (query && queryKeys.length > 0) {
        qs += '?'
        queryKeys.forEach((key, index) => {
          qs += `${key}=${query[key]}`
          qs += index < queryKeys.length - 1 ? '&' : ''
        })
      }
      return encodeURI(qs)
    },

    /**
     * apiRequest
     * prepares a xhrhttprequest according to the given parameters
     * @param param0
     * @returns
     */
    apiRequest: <T>(
      {
        errorCode,
        route,
        method,
        query, // A dictionary containing values to convert to a URL query string (e.g. ?a=yyy&b=zzz)
        body = {}, // Can be FormData, JSON, text, or Object (unknown type). Use contentType to specify which
        headers,
        credentials = true,
        contentType, // Chosen using XHR_CONTENT_TYPE enumerator. Defaults to 'application/json'
        baseURL,
        requiresFeedback = false,
        convertDates = false
      }: APIRequestPayload,
      callback?: () => void
    ): Promise<APIResponse<T>> => {
      // Set token if available
      const ct = contentType || XHR_CONTENT_TYPE.JSON
      const _baseUrl = baseURL !== undefined ? baseURL : serverBaseUrl

      // Set headers
      headers = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': ct,
        ...headers
      }

      let data: XMLHttpRequestBodyInit

      if (ct !== (XHR_CONTENT_TYPE.MULTIPART as string)) {
        // Convert body to correct format based on contentType
        if (typeof body === 'string' && ct === (XHR_CONTENT_TYPE.URLENCODED as string)) {
          data = body
        } else {
          data = JSON.stringify(body)
        }
      } else {
        data = body as FormData
        delete headers['Content-Type'] // Adding an explicit content type causes problems with Multer. Allow the browser to set it.
      }

      return new Promise<APIResponse<T>>((resolve) => {
        const xhr = new XMLHttpRequest()

        // Handles errors that might occur during or after the request
        const handleError = (
          status: number,
          error?: HttpException,
          callback?: () => void
        ): void => {
          if (error) console.error(error)
          if (callback !== undefined) callback

          dialogStore.actions.pushMessage(
            `API error. status: ${status.toString()} code: ${errorCode}`,
            DialogMessageType.Error,
            error && error.message,
            4000
          )
        }

        // Event listener must be added before calling open()
        xhr.addEventListener('loadend', () => {
          const response = convertDates ? parseWithDate(xhr.response) : xhr.response

          // regular requests must be wrapped in an APIResponse
          const res = {
            status: xhr.status,
            dataType: XHR_CONTENT_TYPE.JSON,
            data: response as T
          } as APIResponse<T>
          if (xhr.status >= 400) {
            const exception = new HttpException(xhr.status, `Call to ${route}: ${xhr.statusText}`)
            res.error = exception
            res.data = undefined
          } else if (requiresFeedback)
            dialogStore.actions.pushMessage(
              xhr.status.toString(),
              DialogMessageType.Success,
              getSuccessTag()
            )
          resolve(res)
        })

        xhr.addEventListener('error', (error: ProgressEvent) => {
          handleError(
            xhr.status,
            new HttpException(xhr.status, `Progress error: ${error.type}`),
            callback
          )
        })

        let encodedQueries = ''
        if (query !== undefined) {
          // query is an optional parameter
          encodedQueries = base.getEncodedParams(query)
        }

        const url = `${_baseUrl}${route}${encodedQueries}`

        xhr.open(method, url)
        xhr.responseType = 'json'
        xhr.withCredentials = credentials

        if (headers !== undefined) {
          // headers is an optional argument
          for (const key of Object.keys(headers)) {
            xhr.setRequestHeader(key, headers[key])
          }
        }

        try {
          if (method === XHR_REQUEST_TYPE.GET) {
            xhr.send()
          } else {
            xhr.send(data)
          }
        } catch (error: unknown) {
          console.error(error)
        }
      })
    }
  }

  const pipeline = {
    createSession: async (groupName: string): Promise<APIResponse<object>> => {
      return base.apiRequest<Response>({
        errorCode: ErrorCode.NURSING,
        route: `/pipeline/${groupName}`,
        method: XHR_REQUEST_TYPE.POST,
        credentials: true,
        contentType: XHR_CONTENT_TYPE.JSON,
        baseURL: apiBaseURL
      })
    },
    runPipeline: async (
      sessionId: string,
      modules?: ModuleType[]
    ): Promise<APIResponse<object>> => {
      return base.apiRequest<Response>({
        errorCode: ErrorCode.NURSING,
        route: `/pipeline/${sessionId}`,
        method: XHR_REQUEST_TYPE.PUT,
        credentials: true,
        contentType: XHR_CONTENT_TYPE.JSON,
        baseURL: apiBaseURL,
        body: {
          modules
        }
      })
    }
  }
  const request = {
    getGroup: async (callback?: () => void): Promise<APIResponse<Response>> => {
      return base.apiRequest<Response>(
        {
          errorCode: ErrorCode.LOGIN,
          route: `/group`,
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
          contentType: XHR_CONTENT_TYPE.JSON,
          baseURL: apiBaseURL
        },
        callback
      )
    },

    getGroups: async (callback?: () => void): Promise<APIResponse<NursingGroup[]>> => {
      return base.apiRequest<NursingGroup[]>(
        {
          errorCode: ErrorCode.LOGIN,
          route: `/groups`,
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
          contentType: XHR_CONTENT_TYPE.JSON,
          baseURL: apiBaseURL
        },
        callback
      )
    },

    getDataCSV: async (callback?: () => void): Promise<APIResponse<DataCSVRow>> => {
      return base.apiRequest<DataCSVRow>(
        {
          errorCode: ErrorCode.NURSING,
          route: `/file/codings`,
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
          contentType: XHR_CONTENT_TYPE.JSON,
          baseURL: apiBaseURL
        },
        callback
      )
    },

    getGroupByRow: async (rowId: string, callback?: () => void): Promise<APIResponse<Response>> => {
      return base.apiRequest<Response>(
        {
          errorCode: ErrorCode.LOGIN,
          route: `/group/${rowId}`,
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
          contentType: XHR_CONTENT_TYPE.JSON,
          baseURL: apiBaseURL
        },
        callback
      )
    },

    fetchUserDetails: async (callback?: () => void): Promise<APIResponse<IUser>> => {
      return base.apiRequest<IUser>(
        {
          errorCode: ErrorCode.USER,
          route: `/user`,
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
          contentType: XHR_CONTENT_TYPE.JSON,
          baseURL: apiBaseURL
        },
        callback
      )
    },

    getSessionByRow: async (
      rowId: string,
      callback?: () => void
    ): Promise<APIResponse<Response>> => {
      return base.apiRequest<Response>(
        {
          errorCode: ErrorCode.LOGIN,
          route: `/sessions/${rowId}`,
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
          contentType: XHR_CONTENT_TYPE.JSON,
          baseURL: apiBaseURL
        },
        callback
      )
    },

    updateSegments: async (
      sessionId: string,
      segments: Segment[],
      callback?: () => void
    ): Promise<APIResponse<Response>> => {
      return base.apiRequest<Response>(
        {
          errorCode: ErrorCode.LOGIN,
          route: `/session/${sessionId}/segments`,
          method: XHR_REQUEST_TYPE.PUT,
          credentials: true,
          contentType: XHR_CONTENT_TYPE.JSON,
          baseURL: apiBaseURL,
          body: segments
        },
        callback
      )
    },

    test: async (callback?: () => void): Promise<APIResponse<object>> => {
      return base.apiRequest<object>(
        {
          errorCode: ErrorCode.LOGIN,
          route: `/test`,
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
          contentType: XHR_CONTENT_TYPE.JSON,
          baseURL: apiBaseURL
        },
        callback
      )
    },

    logout: async (callback?: () => void): Promise<void> => {
      await base.apiRequest(
        {
          errorCode: ErrorCode.LOGIN,
          route: `/auth/logout`,
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
          baseURL: apiBaseURL
        },
        callback
      )
    }
  }

  return { base, request, pipeline }
}

export default useApiService
