/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 Seez is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 Seez is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with Seez.  If not, see <http://www.gnu.org/licenses/>.
 */
import type { APIResponseCode, ErrorCode, XHR_CONTENT_TYPE, XHR_REQUEST_TYPE } from '@/constants.js'
import type { Group } from '@models'
import type { UserRole } from '@database'

export interface OptionItem {
  id: string
  title: string
}

// ---------------  Models -----------------

export interface CallbackOneParam<T, U = void> {
  (arg?: T): U
}
export interface Callback {
  (...args: unknown[]): unknown
}

export interface IUser {
  _id: string
  status: {
    lastLogin: string | Date
    created: string | Date
    browserLanguage: string
    isRegistered: boolean
  }
  profile: {
    username: string
    fullName: string
    provider: string // Dataporten or Canvas etc.?
    email: string
    language: string
    organization: string
    // Canvas courses ,zoom meeting IDs /dataporten groups the user is a member of
    groups: Group[]
    role: UserRole
  }
}
export class User implements IUser {
  _id = ''
  status: {
    lastLogin: Date
    created: Date
    browserLanguage: string
    isRegistered: boolean
  }
  profile: {
    username: string
    fullName: string
    provider: string // Dataporten or Canvas etc.?
    email: string
    language: string
    organization: string
    // Canvas courses ,zoom meeting IDs /dataporten groups the user is a member of
    groups: Group[]
    role: UserRole
  }

  constructor(spec: IUser) {
    this._id = spec._id
    this.status = {
      created: new Date(spec.status.created),
      lastLogin: new Date(spec.status.lastLogin),
      browserLanguage: spec.status.browserLanguage,
      isRegistered: spec.status.isRegistered
    }
    this.profile = {
      username: spec.profile.username,
      fullName: spec.profile.fullName,
      provider: spec.profile.provider,
      email: spec.profile.email,
      language: spec.profile.language,
      organization: spec.profile.organization,
      // Canvas courses ,zoom meeting IDs /dataporten groups the user is a member of
      groups: spec.profile.groups || [],
      role: spec.profile.role
    }
  }
}

export interface LocalUser extends Record<string, unknown> {
  id: string
  jwt: string
  lastLogin: Date
  pin: string
  name: string
  selected: boolean
}
// General App settings that should be saved to disk
export interface PersistedAppState extends Record<string, unknown> {
  localUsers: Record<string, LocalUser>
}

export interface DialogConfig {
  title: string
  text: string
  visible: boolean
  confirm: Callback
  confirmText: string
  cancel: Callback
  cancelText: string
}

// ---------------  API -----------------

export interface responseData {
  studentsResponse: Record<string, unknown>
  teachersResponse: Record<string, unknown>
}

// Augment the Error class with message and status
export class HttpException extends Error {
  status: number
  message: string
  constructor(status: number, message: string) {
    super(message)
    this.status = status
    this.message = message
  }
}

export interface APIRequestPayload {
  errorCode: ErrorCode
  method: XHR_REQUEST_TYPE
  route: string
  credentials?: boolean
  body?: unknown | string | User | FormData
  headers?: Record<string, string>
  query?: Record<string, string>
  contentType?: string
  baseURL?: string
  convertDates?: boolean
  requiresFeedback?: boolean
}

export interface XHRPayload {
  url: string
  headers: Record<string, string>
  credentials: boolean
  body: string | FormData
  method: XHR_REQUEST_TYPE
  convertDates?: boolean
}

export interface CmsGQLData {
  __typename: string
  id?: string
  flatData?: Record<string, unknown>
  data?: Record<string, unknown>
  errors?: []
  access_token?: string
}

export interface CmsGQLQuery {
  data?: {
    results: CmsGQLData[] | CmsGQLData
    items?: CmsGQLData[]
  }
  errors?: { message: string }[]
  access_token?: string
}

/* API / Store */

export interface StoreResponse {
  success: boolean
  error?: Error[] | Error | HttpException[]
}

export interface ExtendedStoreResponse<T> extends StoreResponse {
  data: T
}

export interface APIResponse<T> {
  status: APIResponseCode
  error?: HttpException
  errors?: HttpException[]
  dataType: XHR_CONTENT_TYPE
  data: T | undefined
}

export interface TokenResponse {
  access_token: string
  expires_in: number
  token_type: string
  scope: string
}
