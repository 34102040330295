import { computed, reactive } from 'vue'
import type { ComputedRef } from 'vue'
import { ErrorCode, PROMPTYPE, SORTTYPE, XHR_CONTENT_TYPE, XHR_REQUEST_TYPE } from '@/constants.js'
import useApiService from '@/api/apiRequest.js'
import { useCMSStore } from './CMSStore.js'
import type { APIRequestPayload } from '@/models/main.js'
import {
  type IPieData,
  PromptSchema,
  Contribution,
  DocumentMetadata,
  type IPath,
  Message,
  type IDocumentFeedback
} from '@/models/legal.js'
import type { IDocumentMetadataResponse, IMessageAggregate, IMessageResponse } from '@teams'

interface IMsgAgg {
  mentionsCount: number
  reactionsCount: number
  attachmentsCount: number
  messageCount: number
}
interface AIResponse {
  transcript: string
  resultFromPrompt: string
}

interface BarChart {
  date: number
  [key: string]: number
}

interface State {
  selectedTeam: string // id
  isTesting: boolean
  anonymiseId: Map<string, string>
  contributions: IPieData[]
  teamMembers: Record<string, string[]>
  transcribedText: string | undefined
  allMessages: Message | undefined

  messageAgg: IMsgAgg | undefined

  barPostMap: BarChart[]
  taskFeedback: IDocumentFeedback[]
  meetingFeedback: IDocumentFeedback[]
  documentMetaDataAnalytics: DocumentMetadata
  version: IPieData[]
}
const state: State = reactive({
  selectedTeam: '',
  isTesting: false,
  anonymiseId: new Map(),
  contributions: [],
  teamMembers: {},
  transcribedText: '',
  allMessages: undefined,

  messageAgg: undefined,

  barPostMap: [],
  taskFeedback: [],
  meetingFeedback: [],
  documentMetaDataAnalytics: {
    totalModifications: 0,
    totalUploads: 0,
    aggVersionbyUser: {},
    aggUploadbyUser: {},
    versionInfo: []
  },
  version: []
})
// ------------  Internal functions ------------

const { getters: CMSGetters } = useCMSStore()
const apiService = useApiService()

// Trial to see how Open ai works
async function fetchOpenAi(audio: FormData): Promise<AIResponse> {
  const payload: APIRequestPayload = {
    errorCode: ErrorCode.TEAMS,
    method: XHR_REQUEST_TYPE.POST,
    credentials: true,
    route: '/api/teams/resultfromAI',
    body: audio,
    contentType: XHR_CONTENT_TYPE.MULTIPART
  }
  const response = await apiService.base.apiRequest<AIResponse>(payload)
  return (
    response.data ?? {
      transcript: '',
      resultFromPrompt: ''
    }
  )
}

/**
 * Upload file for meeting or Document analytics
 * @param file Actuall file
 * @param path path needs team_id and type of construct
 * @returns if uploaded correctly
 */
async function postUpload(file: FormData, path: IPath): Promise<void> {
  const payload: APIRequestPayload = {
    errorCode: ErrorCode.TEAMS,
    method: XHR_REQUEST_TYPE.POST,
    credentials: true,
    route: '/api/teams/upload',
    body: file,
    query: {
      team: path.selectedTeam,
      mode: path.selectedMode
    },
    contentType: XHR_CONTENT_TYPE.MULTIPART
  }
  const response = await apiService.base.apiRequest<void>(payload)
  return response.data
}

// Document feedback
async function fetch_document_feedback(documentPrompt: PromptSchema): Promise<IDocumentFeedback[]> {
  const payload: APIRequestPayload = {
    errorCode: ErrorCode.TEAMS,
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/teams/taskFeedback',
    query: {
      prompt: documentPrompt.prompt,
      systemPrompt: documentPrompt.instruction,
      promptType: documentPrompt.promptType,
      team_id: state.selectedTeam || ''
    }
  }
  const response = await apiService.base.apiRequest<IDocumentFeedback[]>(payload)
  return response.data ?? []
}

// To fetch document metadata from teams endpoint
async function fetchDocumentMetadata(): Promise<IDocumentMetadataResponse> {
  const payload: APIRequestPayload = {
    errorCode: ErrorCode.TEAMS,
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/documents/metadata',
    query: {
      team_id: state.selectedTeam || ''
    }
  }
  const response = await apiService.base.apiRequest<IDocumentMetadataResponse>(payload)
  return (
    response.data ?? {
      totalUploads: 0,
      totalModifications: 0,
      aggVersionbyUser: {},
      aggUploadbyUser: {},
      versionInfo: []
    }
  )
}

// To list all messages for both Teams and chats for a selected group
async function fetchMessages(
  options: { testing: boolean } = { testing: false }
): Promise<IMessageResponse> {
  const payload: APIRequestPayload = {
    errorCode: ErrorCode.TEAMS,
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/teams/messages',
    query: {
      team_id: state.selectedTeam || '',
      testing: options.testing ? 'true' : 'false'
    }
  }
  const response = await apiService.base.apiRequest<IMessageResponse>(payload)
  return (
    response.data ?? {
      messageAggregate: [],
      postCount: []
    }
  )
}

// To fetch all Teams the user belongs too
async function fetchTeamMembers(options: { testing: boolean } = { testing: false }) {
  const payload: APIRequestPayload = {
    errorCode: ErrorCode.TEAMS,
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: `/api/teams/members`,
    query: {
      testing: options.testing ? 'true' : 'false'
    }
  }
  const response = await apiService.base.apiRequest<Record<string, string[]>>(payload)
  return response.data
}

interface Actions {
  selectTeam: (team: string) => void
  setIsTesting: (isTesting: boolean) => void
  getTeamMembers: () => Promise<void>
  getMessages: () => Promise<void>
  getResultsFromOpenAi: (audio: FormData) => Promise<void>
  uploadFile: (file: FormData, path: IPath) => Promise<void>
  getDocumentFeedback: (mode: string) => Promise<void>
  getDocumentMetadata: () => Promise<void>
  getVersion: (fileId: string) => Promise<void>
  sortBy: (mode: string) => Promise<void>
}

interface Getters {
  selectedTeam: ComputedRef<string | undefined>
  anonymiseId: ComputedRef<Map<string, string>>
  contributions: ComputedRef<IPieData[]>
  allTeams: ComputedRef<string[]>
  transcribedText: ComputedRef<string | undefined>
  messageAgg: ComputedRef<IMsgAgg | undefined>
  allMessages: ComputedRef<Message | undefined>
  barPostMap: ComputedRef<BarChart[]>
  taskFeedback: ComputedRef<IDocumentFeedback[]>
  meetingFeedback: ComputedRef<IDocumentFeedback[]>
  documentMetaDataAnalytics: ComputedRef<DocumentMetadata>
  version: ComputedRef<IPieData[]>
}

interface ServiceInterface {
  state: State
  actions: Actions
  getters: Getters
}

export function useTeamStore(): ServiceInterface {
  const actions = {
    /**
     * Get all the Teams the user is part of
     * @returns
     */
    getTeamMembers: async function (): Promise<void> {
      state.teamMembers = {}
      const teamsRes = await fetchTeamMembers({ testing: state.isTesting })
      if (teamsRes) {
        state.teamMembers = teamsRes
      }
    },

    setIsTesting: function (isTesting = false): void {
      state.isTesting = isTesting
    },

    /**
     * Select the team for the analytics to be shown
     * @param teamId
     * @returns
     */
    selectTeam: function (team: string = ''): void {
      let t = team
      const itemInStorage = sessionStorage.getItem(team)
      if (itemInStorage) t = itemInStorage
      if (t) {
        state.selectedTeam = t
        state.anonymiseId = new Map()
        sessionStorage.setItem('teamId', t)
      }
    },

    /**
     *  fetch all the messages for the selected team
     */
    getMessages: async function (): Promise<void> {
      const response: IMessageResponse = await fetchMessages({ testing: state.isTesting })
      state.allMessages = new Message(response)
      state.messageAgg = state.allMessages.messageAggregate.reduce(
        (acc: IMsgAgg, curr: IMessageAggregate) => {
          acc.mentionsCount = curr.mentionsCount + acc.mentionsCount
          acc.messageCount = curr['body.content'] + acc.messageCount
          acc.reactionsCount = curr.reactionsCount + acc.reactionsCount
          acc.attachmentsCount = curr.attachmentsCount + acc.attachmentsCount

          return acc
        },
        {
          mentionsCount: 0,
          reactionsCount: 0,
          attachmentsCount: 0,
          messageCount: 0
        }
      )
      state.allMessages.messageAggregate.forEach((item) =>
        state.contributions.push(new Contribution(item))
      )
      // Count the messages for each user
      state.barPostMap = state.allMessages.postCount.map((msg) => {
        return {
          date: msg.createdDateTime as unknown as number,
          [msg['from.user.displayName']]: msg.postCount
        }
      })
      return Promise.resolve()
    },
    getResultsFromOpenAi: async (audio: FormData): Promise<void> => {
      const response: AIResponse = await fetchOpenAi(audio)
      state.transcribedText = response.transcript
      return Promise.resolve()
    },
    uploadFile: async (file: FormData, path: IPath): Promise<void> => {
      await postUpload(file, path)
      alert('Uploaded')
      return Promise.resolve()
    },

    getDocumentFeedback: async (mode: string): Promise<void> => {
      const documentPrompt = CMSGetters.prompts.value.get(PROMPTYPE[mode as keyof typeof PROMPTYPE])
      state.taskFeedback = []
      state.meetingFeedback = []
      if (documentPrompt) {
        const response = await fetch_document_feedback(documentPrompt as PromptSchema)
        if (mode == PROMPTYPE.Document) {
          response.forEach((item) => state.taskFeedback.push(item))
        } else {
          response.forEach((item) => state.meetingFeedback.push(item))
        }
        return Promise.resolve()
      }
    },

    getDocumentMetadata: async (): Promise<void> => {
      const response = await fetchDocumentMetadata()
      state.documentMetaDataAnalytics = new DocumentMetadata(response)
      state.documentMetaDataAnalytics.versionInfo.map((item) => {
        const gptFeedback = state.taskFeedback.find(
          (feedback) => feedback.filename == item.fileName
        )
        if (gptFeedback) {
          const index = state.taskFeedback.indexOf(gptFeedback)
          if (index > -1) {
            state.taskFeedback.splice(index, 1)
          }
          item.Feedback = gptFeedback?.filefeedback as string
        }
      })
      return Promise.resolve()
    },

    getVersion: async (fileName: string): Promise<void> => {
      state.version = []
      state.documentMetaDataAnalytics.versionInfo.map((item) => {
        if (item.fileName == fileName) {
          for (const [key, value] of Object.entries(item.versionByUser)) {
            console.log(`${key}: ${value}`)
            const obj: IPieData = {
              key: key,
              value: value
            }
            state.version.push(obj)
          }
        }
      })
    },
    sortBy: async (mode: string): Promise<void> => {
      if (mode == SORTTYPE.date) {
        state.documentMetaDataAnalytics.versionInfo.sort((a, b) => {
          const dateA = new Date(a.createdDate.replace(/\./g, '-'))
          const dateB = new Date(b.createdDate.replace(/\./g, '-'))

          return dateB.getTime() - dateA.getTime()
        })
      } else if (mode == SORTTYPE.title) {
        state.documentMetaDataAnalytics.versionInfo.sort((a, b) =>
          b.fileName.localeCompare(a.fileName)
        )
      }
    }
  }

  const getters = {
    get selectedTeam(): ComputedRef<string | undefined> {
      return computed(() => state.selectedTeam) // This is the 'currently selected' Team
    },
    get anonymiseId(): ComputedRef<Map<string, string>> {
      return computed(() => state.anonymiseId)
    },
    get contributions(): ComputedRef<IPieData[]> {
      return computed(() => state.contributions)
    },
    get allTeams(): ComputedRef<string[]> {
      return computed(() => Object.keys(state.teamMembers)) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
    },
    get allMessages(): ComputedRef<Message | undefined> {
      return computed(() => state.allMessages) // An array of posts with channel_ids as a key
    },
    get messageAgg(): ComputedRef<IMsgAgg | undefined> {
      return computed(() => state.messageAgg)
    },

    get barPostMap(): ComputedRef<BarChart[]> {
      return computed(() => state.barPostMap)
    },

    get transcribedText(): ComputedRef<string | undefined> {
      return computed(() => state.transcribedText)
    },
    get taskFeedback(): ComputedRef<IDocumentFeedback[]> {
      return computed(() => state.taskFeedback)
    },
    get meetingFeedback(): ComputedRef<IDocumentFeedback[]> {
      return computed(() => state.meetingFeedback)
    },
    get documentMetaDataAnalytics(): ComputedRef<DocumentMetadata> {
      return computed(() => state.documentMetaDataAnalytics)
    },
    get version(): ComputedRef<IPieData[]> {
      return computed(() => state.version)
    }
  }

  return { state, actions, getters }
}

export type useTeamStore = ReturnType<typeof useTeamStore>
