<template>
  <div class="bg-tl-green-200 rounded-20px p-20px">
    <div
      v-for="(row, index) in data"
      ref="segmentContainer"
      :id="`datarow-${index}`"
      :key="`datarow-${index}`"
      class="scroll-my-4 flex flex-col"
    >
      <div
        class="flex flex-row text-white bg-tl-green-200 border border-tl-green-400 justify-center m-1 mx-2 rounded-md"
        :class="segmentStyles(index)"
      >
        <div class="rounded-l-md w-28 px-2 text-center">{{ row.speaker }}</div>
        <p class="w-8">&rightarrow;</p>
        <div class="w-28 text-center">{{ row.receiver }}</div>
        <div class="flex flex-grow font-semibold">{{ row.utterance }}</div>
        <div class="rounded-r-md w-24 px-2" :class="row.code ? 'bg-blue-500' : 'bg-tl-green-200'">
          {{ row.code }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, toRefs, watch, type PropType } from 'vue'
import scrollIntoView from 'scroll-into-view-if-needed'

export interface TranscriptRow {
  speaker: string
  receiver: string
  utterance: string
  code: string
  start: number
  end: number
}

const props = defineProps({
  highlight: { type: Boolean, default: true },
  time: { type: Number, required: true },
  trim: { type: Object as PropType<number[]>, required: true },
  data: { type: Object as PropType<TranscriptRow[]>, required: true }
})

const state = reactive({
  duration: 0,
  playerHeight: 300,
  highlightedIndex: 0
})

const segmentContainer = ref()
const { data, highlight } = toRefs(props)

watch(
  () => props.time,
  (newTime) => moveToSegment(newTime)
)

function moveToSegment(newTime: number) {
  // Assumes segments are sorted by startTime
  const index = data.value.findIndex((s) => newTime >= s.start && newTime <= s.end)
  if (index >= 0 && segmentContainer.value) {
    state.highlightedIndex = index
    const id = `datarow-${index}`
    const segmentElement = document.getElementById(id)
    const segmentContainer = document.getElementById('segments')
    if (segmentElement && segmentContainer) {
      scrollIntoView(segmentElement, {
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
        boundary: segmentContainer
      })
    }
  }
}

function segmentStyles(index: number): string {
  return index === state.highlightedIndex && highlight.value
    ? `bg-tl-green-200 opacity-100`
    : 'bg-tl-green-200 opacity-50'
}
</script>
