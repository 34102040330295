<template>
  <div
    class="h-screen w-screen flex flex-col justify-center items-center bg-tl-grey-300 dark:bg-gray-800"
  >
    <h1 class="text-3xl">Welcome to Teamlearn</h1>
    <br />
    <p class="text-yellow-400 cursor-pointer" @click="login()">Login</p>
  </div>
</template>

<script setup lang="ts">
import router from '@/router'

function login() {
  router.push('/login')
}
</script>
