<template>
  <div class="h-screen w-screen flex flex-col justify-center items-center bg-gray-800 text-white">
    <h1 class="text-3xl">Welcome to Teamlearn</h1>
    <div class="flex flex-row justify-around w-1/2 pt-12">
      <p class="text-yellow-400 cursor-pointer" @click="legal()">LegalTech</p>
      <p class="text-yellow-400 cursor-pointer" @click="nursing()">Nursing</p>
    </div>

    <div v-if="!loading" class="flex flex-col items-center mt-6">
      <p>Select a team to view analytics</p>
      <ul class="flex flex-row mt-6">
        <li
          class="cursor-pointer opacity-80 hover:opacity-100 border rounded-md p-2 mx-3"
          v-for="(team, index) in teamGetters.allTeams.value"
          :key="index"
          @click="selectTeam(team)"
        >
          <p>{{ team }}</p>
        </li>
      </ul>
    </div>
    <div v-else class="flex flex-col items-center">
      <p>Loading {{ loading }}</p>
      <PulseLoader class="mt-6" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useTeamStore } from '@/composition/stores/teamStore.js'
import { useUserStore } from '@/composition/stores/userStore.js'
import router from '@/router'
import { useCMSStore } from '@/composition/stores/CMSStore.js'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

const { getters: teamGetters, actions: teamActions } = useTeamStore()
const { actions: userActions } = useUserStore()
const { actions: CMSActions } = useCMSStore()
const loading = ref('')
const route = useRoute()

onMounted(async () => {
  loading.value = 'teams'
  if (route.query['testing']) {
    teamActions.setIsTesting(true)
  }
  try {
    await userActions.getUserDetails()
    await teamActions.getTeamMembers()
    await CMSActions.getPrompts()

    const teams = teamGetters.allTeams.value
    if (teams.length === 1) selectTeam(teams[0])
  } catch (error) {
    console.log(error)
  }
  loading.value = ''
})

function legal() {
  router.push('/legal')
}
function nursing() {
  router.push('/nursing/groups')
}

const selectTeam = async (team: string) => {
  loading.value = 'messages'
  teamActions.selectTeam(team)
  await teamActions.getMessages()
  loading.value = ''
  router.push('/legal')
}
</script>
