<template>
  <div>
    <div
      id="menubar"
      class="h-14 flex items-center px-3 w-full justify-start text-slate-white bg-gray-200 dark:bg-black fadein"
    >
      <div
        class="cursor-pointer"
        @click="navigateToDashboard()"
        @touchstart.prevent="navigateToDashboard()"
      >
        <img class="h-9 block dark:hidden" :src="Logo" />
        <img class="h-9 hidden dark:block" :src="LogoBright" />
      </div>
      <div class="flex flex-grow"></div>
      <div
        v-if="router.currentRoute.value.name != 'Home'"
        class="mx-3 flex cursor-pointer"
        @click="navigateToCaseDashboard()"
      >
        <img class="w-3 rotate-90 mr-3 dark:invert" :src="ArrowIcon" />
        <p class="text-sm dark:text-white">Back</p>
      </div>
      <div class="mx-3 flex cursor-pointer dark:invert" @click="appStore.actions.toggleDarkMode()">
        <img class="w-3 mr-3" :src="appStore.getters.darkMode.value ? MoonIcon : SunIcon" />
      </div>
      <div class="flex justify-center items-center">
        <router-link class="text-white bg-tl-grey-200 px-3 py-2 rounded-3xl" to="/about">{{
          t('about')
        }}</router-link>
        <div
          v-if="userStore.getters.user.value?._id"
          class="w-px h-9 dark:bg-white bg-black mx-5"
        ></div>
        <div v-if="userStore.getters.user.value?._id">
          <div
            class="bg-tl-red-400 w-9 h-9 rounded-full flex justify-center items-center font-bold text-white"
            @click="state.menuIsVisible = !state.menuIsVisible"
          >
            {{ userStore.getters.user.value?.profile.username[0] }}
          </div>
          <div
            v-if="state.menuIsVisible"
            class="absolute z-50 right-2 top-16 bg-tl-grey-100 rounded-xl"
          >
            <SlButton class="" @click="logout()"> Logout </SlButton>
          </div>
        </div>
        <div>
          <!-- TODO: future language switch -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { User } from '@/models/main.js'

import { useUserStore } from '@/composition/stores/userStore'
import { useAppStore } from '@/composition/stores/appStore'

import useDialogStore from '@/composition/dialog'
import { DialogMessageType } from '@/interfaces'

import SlButton from '@/components/base/SlButton.vue'
import ArrowIcon from '@/assets/icons/fontawesome/arrow-down-regular.svg'
import MoonIcon from '@/assets/icons/fontawesome/moon.svg'
import SunIcon from '@/assets/icons/fontawesome/sun.svg'
import Logo from '@/assets/teamlearn-logo-black.png'
import LogoBright from '@/assets/teamlearn-logo-white.png'
import { UserRole } from '@database'

const userStore = useUserStore()
const appStore = useAppStore()
const dialogStore = useDialogStore()
const router = useRouter()

const messages = {
  no: { tasks: 'Oppgaver', about: 'Om' },
  en: { tasks: 'Tasks', about: 'About' }
}

const { t } = useI18n({ messages })

const state = reactive({
  user: {} as User,
  menuIsVisible: false,
  darkMode: false
})

onBeforeMount(() => {
  const user = userStore.getters.user.value
  if (user) state.user = user
})

const isAdmin = computed(() => {
  const user = userStore.getters.user.value
  return user ? user.profile.role === UserRole.admin : false
})

function navigateToCaseDashboard() {
  if (router.currentRoute.value.fullPath.includes('legal')) router.push('/legal')
  else router.push('/nursing/groups')
}

function navigateToDashboard() {
  if (isAdmin.value) router.push('/dashboard')
}

async function logout() {
  await userStore.actions.logout()
  router.push(`/`)
  dialogStore.actions.pushMessage('You have been logged out', DialogMessageType.Information)
}
</script>

<style scoped></style>
