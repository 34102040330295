import { ref, computed, type ComputedRef, type Ref } from 'vue'
import router from '@/router/index.js'
import useApiService from '@/api/apiRequest.js'
import { type LocalUser } from '@/models/main.js'
import { ErrorCode, LanguageCodes, XHR_REQUEST_TYPE } from '@/constants.js'

// ------------  Types --------------
interface AppStatus {
  validToken: boolean
  loading: boolean
  // isMobileApp: boolean
}
export interface AppState {
  validToken: boolean
  feedbackMessage: string
  loading: boolean
  fade: boolean
  lastLogin: Date
  currentLocalUser: LocalUser | undefined
  // appIsOld: boolean
  disableDelays: boolean
  languageCode: LanguageCodes
  selectedToolMode: string
  selectedCase: string
  darkMode: boolean
}

const apiService = useApiService()

// ------------  State (internal) --------------
const _appState: Ref<AppState> = ref({
  validToken: false,
  feedbackMessage: '',
  loading: false,
  fade: false,
  lastLogin: new Date(),
  currentLocalUser: undefined,
  appIsOld: false,
  languageCode: LanguageCodes.en,
  disableDelays: import.meta.env.VITE_DISABLE_DELAYS === 'true',
  selectedToolMode: '',
  selectedCase: '',
  darkMode: localStorage.getItem('darkMode') === 'true' ? true : false
})

// ------------  Getters (Read only) --------------
interface Getters {
  status: ComputedRef<AppStatus>
  fade: ComputedRef<boolean>
  // disableDelays: ComputedRef<boolean>
  currentLocalUser: ComputedRef<LocalUser | undefined>
  // persistedLocalUsers: ComputedRef<Record<string, LocalUser>>
  languageCode: ComputedRef<LanguageCodes>
  feedbackMessage: ComputedRef<string>
  selectedCaseMode: ComputedRef<string>
  darkMode: ComputedRef<boolean>
}
const getters = {
  get darkMode(): ComputedRef<boolean> {
    return computed(() => _appState.value.darkMode)
  },
  get feedbackMessage(): ComputedRef<string> {
    return computed(() => _appState.value.feedbackMessage)
  },
  get languageCode(): ComputedRef<LanguageCodes> {
    return computed(() => _appState.value.languageCode)
  },
  get status(): ComputedRef<AppStatus> {
    return computed(() => ({
      loading: _appState.value.loading,
      validToken: _appState.value.validToken
    }))
  },
  // Current state of the display 'fade'
  get fade(): ComputedRef<boolean> {
    return computed(() => _appState.value.fade)
  },
  // // TESTING ONLY. Remove app delays
  // get disableDelays(): ComputedRef<boolean> {
  //   return computed(() => _appState.value.disableDelays)
  // },
  get currentLocalUser(): ComputedRef<LocalUser | undefined> {
    return computed(() => {
      return _appState.value.currentLocalUser ? _appState.value.currentLocalUser : undefined
    })
  },
  get selectedCaseMode(): ComputedRef<string> {
    return computed(() => _appState.value.selectedCase)
  }
  // get persistedLocalUsers(): ComputedRef<Record<string, LocalUser>> {
  //   return computed(() => _persistedAppState.value.localUsers)
  // },
}
// ------------  Actions --------------
interface Actions {
  // setDisableDelays: (s: boolean) => void
  setFeedbackMessage: (message: string) => void
  setLoading: (loading: boolean) => void
  toggleDarkMode: () => void
  setLanguageCode: (lang: LanguageCodes) => void
  setFade: (fade: boolean) => void
  setCurrentLocalUser: (user: LocalUser) => void
  logout: (rememberMe: boolean) => Promise<void>
  tokenLogin: () => Promise<boolean>
  setselectedCaseMode: (mode: string) => void
  // loadSettings: () => Promise<void>

  // saveSettings: () => Promise<void>
  // detectOldApp: () => Promise<void>
  // setSelectTool: (mode: string) => Promise<void>
}
const actions = {
  // // FOR TESTING ONLY. Remove app delays
  // setDisableDelays(s: boolean) {
  //   _appState.value.disableDelays = s
  // },
  setFeedbackMessage: function (message: string): void {
    _appState.value.feedbackMessage = message
  },
  toggleDarkMode: function (): void {
    _appState.value.darkMode = !_appState.value.darkMode
    localStorage.setItem('darkMode', _appState.value.darkMode ? 'true' : 'false')
  },
  setLoading: function (loading: boolean): void {
    _appState.value.loading = loading
  },
  setLanguageCode: function (languageCode: LanguageCodes): void {
    _appState.value.languageCode = languageCode
  },
  // Fade the display down or up
  setFade: function (fade: boolean): void {
    _appState.value.fade = fade
  },
  setCurrentLocalUser: function (user: LocalUser): void {
    _appState.value.currentLocalUser = user
    localStorage.setItem('jwt', user.jwt)
  },
  setselectedCaseMode: function (mode: string): void {
    _appState.value.selectedCase = mode
  },
  // setSelectTool: function (mode: string): Promise<void> {
  //   _appState.value.selectedToolMode = mode
  //   return Promise.resolve()
  // },
  // The intention of logout is to enforce a new login with the server
  logout: function (): Promise<void> {
    if (_appState.value.currentLocalUser) {
      _appState.value.currentLocalUser = undefined
    }
    localStorage.removeItem('jwt')
    return Promise.resolve()
    // return this.saveSettings()
  },
  // Call server for the current version of the app
  // detectOldApp: async function (): Promise<void> {
  //   const payload: APIRequestPayload = {
  //     method: XHR_REQUEST_TYPE.GET,
  //     credentials: false,
  //     route: '/api/appversion',
  //     contentType: 'text/html',
  //   }
  //   let version = ''
  //   try {
  //     version = await apiRequest<string>(payload)
  //   } catch (error) {
  //     console.log(`Error getting server version: ${(error as Error).toString()}`)
  //   }
  //   if (appVersion !== version) {
  //     _appState.value.appIsOld = true
  //   }
  // },
  // loadSettings: function (): Promise<void> {
  //   return new Promise((resolve) => {
  //     const cd: CordovaData = new CordovaData({
  //       fileName: 'settings.json',
  //       readFile: true,
  //       asText: true,
  //       asJSON: true,
  //     })
  //     // If the file does not exist, our cordovaService will create it
  //     return deviceActions.loadFromStorage<PersistedAppState>(cd).then((data) => {
  //       if (data) {
  //         _persistedAppState.value.localUsers = {}
  //         const d = data
  //         Object.keys(d.localUsers).forEach((key) => (_persistedAppState.value.localUsers[key] = d.localUsers[key]))
  //       }
  //       resolve()
  //     })
  //   })
  // },
  // saveSettings: function (): Promise<void> {
  //   const cd: CordovaData = new CordovaData({
  //     fileName: 'settings.json', // Saved to app's root folder
  //     data: _persistedAppState.value,
  //     asText: true,
  //     asJSON: true,
  //   })
  //   return deviceActions.saveToStorage(cd)
  // },
  // Try to exchange token for a session if the token already exists
  tokenLogin: function (): Promise<boolean> {
    return apiService.base
      .apiRequest({
        errorCode: ErrorCode.LOGIN,
        route: '/auth/token',
        method: XHR_REQUEST_TYPE.GET,
        credentials: true
      })
      .then(() => {
        // We now have an active session so proceed as normal..
        router.push('/login')
        return Promise.resolve(true)
      })
      .catch(() => {
        // Exchange was not accepted, clear the token and redirect to login page
        console.log('No valid token. Redirecting to login page..')
        _appState.value.currentLocalUser = undefined
        return Promise.resolve(false)
      })
  }
}
// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export function useAppStore(): ServiceInterface {
  return {
    getters,
    actions
  }
}
export type AppStoreType = ReturnType<typeof useAppStore>
//export const AppKey: InjectionKey<UseApp> = Symbol('UseApp')
