<template>
  <div class="mx-5 dark:bg-gray-800 rounded-md mb-4 flex flex-col">
    <div class="flex flex-row m-2 cursor-pointer">
      <div
        class="rounded-l-md p-2 border-r-2 border-r-white dark:border-r-black bg-black dark:bg-white text-white dark:text-black"
        @click="state.viewMode = ViewMode.group"
      >
        Group
      </div>
      <div
        class="rounded-r-md p-2 bg-black dark:bg-white text-white dark:text-black"
        @click="state.viewMode = ViewMode.individual"
      >
        Individual
      </div>
    </div>
    <div v-if="state.viewMode === ViewMode.group" class="grid grid-cols-2 gap-4 flex-shrink p-2">
      <template v-for="chartData in state.heartData" :key="chartData.student">
        <LineChart
          class="h-72"
          :time="state.currentTime"
          :events="events"
          :line="chartData"
          :darkmode="darkMode"
        ></LineChart>
      </template>
    </div>
    <div v-else class="flex flex-row m-2">
      <div class="flex flex-col w-2/3 mr-2">
        <template v-for="chartData in state.heartData" :key="`chart-heart-${chartData.student}`">
          <LineChart
            v-if="state.selectedSpeaker === chartData.student"
            class="h-72"
            :time="state.currentTime"
            :events="events"
            :line="chartData"
            :darkmode="darkMode"
          ></LineChart>
        </template>
        <template
          v-for="chartData in state.accelerationData"
          :key="`chart-acc-${chartData.student}`"
        >
          <LineChart
            v-if="state.selectedSpeaker === chartData.student"
            class="h-72 mt-2"
            :time="state.currentTime"
            :events="events"
            :line="chartData"
            :darkmode="darkMode"
          ></LineChart>
        </template>
      </div>
      <VideoModule
        :session="sessionGetters.selectedSession.value?.session ?? 1"
        :group="sessionGetters.selectedGroup.value?.name ?? 'group1'"
        @timeupdate="(time: number) => (state.currentTime = time)"
      ></VideoModule>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, watch } from 'vue'
import * as d3 from 'd3'
import { useSessionStore } from '@/composition/stores/sessionStore.js'
import { useAppStore } from '@/composition/stores/appStore.js'
import VideoModule from '@/components/nursing/VideoModule.vue'
import { colours, serverBaseUrl, type Phase, TabName, EventType, EventStatus } from '@/constants'
import { SegmentCode } from '@models'
import type { Line } from '@/components/charts/LineChart.vue'
import LineChart from '@/components/charts/LineChart.vue'
import type { TLEvent } from '@/models/nursing'

enum ViewMode {
  group = 'group',
  individual = 'individual'
}

type ColumnNames =
  | 'Time'
  | 'LN'
  | 'SN1'
  | 'SN2'
  | 'HR'
  | 'S5'
  | 'S6'
  | 'LN - Mag'
  | 'SN1 - Mag'
  | 'SN2 - Mag'
  | 'HR - Mag'
  | 'S5 - Mag'
  | 'S6 - Mag'

interface State {
  currentTime: number
  accelerationData: Line[]
  heartData: Line[]
  viewMode: ViewMode
  checkedNames: string[]
  selectedSpeaker: string
}

const { getters: sessionGetters, actions: sessionActions } = useSessionStore()
const { getters: appGetters } = useAppStore()
const session = sessionGetters.selectedSession
const darkMode = appGetters.darkMode
let data: d3.DSVParsedArray<Record<ColumnNames, number>>

const columnDefs = [
  { name: 'LN', colour: colours[0] },
  { name: 'SN1', colour: colours[1] },
  { name: 'SN2', colour: colours[2] },
  { name: 'HR', colour: colours[3] },
  { name: 'S5', colour: colours[4] },
  { name: 'S6', colour: colours[5] },
  { name: 'LN - Mag', colour: colours[0] },
  { name: 'SN1 - Mag', colour: colours[1] },
  { name: 'SN2 - Mag', colour: colours[2] },
  { name: 'HR - Mag', colour: colours[3] },
  { name: 'S5 - Mag', colour: colours[4] },
  { name: 'S6 - Mag', colour: colours[5] }
]

const HEARTRATE_THRESHOLD = 100

const state: State = reactive({
  currentTime: 0,
  accelerationData: [],
  heartData: [],
  viewMode: ViewMode.group,
  checkedNames: [],
  selectedSpeaker: 'LN'
})

const heartMap = new Map<string, Line>()
const accMap = new Map<string, Line>()

watch(
  () => session.value,
  async () => {
    await callData()
    if (data) setupChart()
  }
)

onMounted(async () => {
  await callData()
  if (data) setupChart()
  setupEvents()
})

async function setupEvents() {
  const iter = heartMap.values()
  const events: TLEvent[] = []
  let currentEvent: TLEvent | undefined

  let result = iter.next()
  while (!result.done) {
    let index = 0
    result.value.data.forEach((lineData, index2) => {
      if (lineData.y > HEARTRATE_THRESHOLD) {
        if (currentEvent) currentEvent.end = lineData.x
        else {
          currentEvent = {
            id: `event-${result.value?.student}-${index}-${index2}`,
            type: EventType.Stress,
            name: `Event ${index}`,
            speaker: result.value?.student ?? '',
            receiver: '',
            utterance: '',
            status: EventStatus.None,
            codes: [],
            start: lineData.x
          }
        }
      } else {
        if (currentEvent) events.push(currentEvent)
        currentEvent = undefined
      }
    })
    result = iter.next()
  }
  sessionActions.setEvents(TabName.StressManagement, events)
}

const events = computed(() => {
  if (session.value) {
    const phases: Phase[] = []
    const segmentsWithPhases = session.value.segments.filter((s) =>
      s.entries.some((e) => e.category === SegmentCode.phase)
    )
    segmentsWithPhases.forEach((s, i) => {
      const phaseEntry = s.entries.find((e) => e.category == SegmentCode.phase)
      if (phaseEntry)
        phases.push({
          key: `phase-key-${i}`,
          start: s.start,
          end: s.end,
          text: phaseEntry.text
        })
    })
    return phases
  } else return []
})

async function callData() {
  if (session.value) {
    function parseRow(d: Record<string, string>) {
      let newRow: Record<ColumnNames, number> = {
        Time: parseInt(d['Time']),
        LN: parseFloat(d['S1 - HR']),
        'LN - Mag': parseFloat(d['S1 - Mag']),
        SN1: parseFloat(d['S2 - HR']),
        'SN1 - Mag': parseFloat(d['S1 - Mag']),
        SN2: parseFloat(d['S3 - HR']),
        'SN2 - Mag': parseFloat(d['S3 - Mag']),
        HR: parseFloat(d['S4 - HR']),
        'HR - Mag': parseFloat(d['S4 - Mag']),
        S5: parseFloat(d['S5 - HR']),
        'S5 - Mag': parseFloat(d['S5 - Mag']),
        S6: parseFloat(d['S6 - HR']),
        'S6 - Mag': parseFloat(d['S6 - Mag'])
      }
      return newRow
    }
    const s = session.value
    data = await d3.csv(
      `${serverBaseUrl}/api/file?group=${s.group}&session=${s.session}&module=StressManagement&index=0`,
      {
        headers: {
          'Content-Type': 'text/csv',
          credentials: 'include'
        }
      },
      parseRow
    )
    console.log('got data')
  }
}

async function setupChart() {
  if (session.value) {
    state.accelerationData = []
    state.heartData = []

    heartMap.clear()
    accMap.clear()

    data.forEach((row) => {
      columnDefs.forEach((entry) => {
        // Initial value
        let sItem: Line = {
          key: entry.name,
          student: entry.name.slice(0, 3).trim(),
          colour: entry.colour,
          yBounds: [0, 0],
          labels: ['x', 'y'],
          data: []
        }

        if (entry.name.includes('- Mag')) {
          if (accMap.has(entry.name)) sItem = accMap.get(entry.name)!
          else accMap.set(entry.name, sItem)
          sItem.yBounds = [400, 1800]
          sItem.labels = ['Time', 'Acceleration Magnitude']
        } else {
          if (heartMap.has(entry.name)) sItem = heartMap.get(entry.name)!
          else heartMap.set(entry.name, sItem)
          sItem.yBounds = [60, 130]
          sItem.labels = ['Time', 'Heart Rate']
        }
        const y = row[entry.name as ColumnNames]
        sItem.data.push({
          x: row.Time,
          y: isNaN(y) ? 0 : y
        })
      })
    })

    columnDefs.forEach((entry) => {
      const accItem = accMap.get(entry.name)
      if (accItem) state.accelerationData.push(accItem)
      const heartItem = heartMap.get(entry.name)
      if (heartItem) state.heartData.push(heartItem)
    })
  }
}
</script>
