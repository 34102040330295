<template>
  <div class="flex flex-col">
    <div class="flex mt-3">
      <div v-for="(rating, index) in ratings" :key="index" class="flex items-center mr-4">
        <div class="w-4 h-4 rounded-full mr-2" :class="rating.color"></div>
        <p>{{ rating.value }}</p>
      </div>
    </div>
    <div v-for="(nettskjemaId, index) in nettskjemaIds" :key="index" class="mt-11 mb-3">
      <table class="w-full max-w-screen-lg">
        <thead>
          <tr class="border-none">
            <th class="float-left">Question</th>
            <th v-for="(user, index) in users" :key="index">Student {{ index + 1 }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(question, index) in Object.keys(NETTSKJEMA_QUESTIONS)"
            :key="index"
            class="border"
          >
            <td class="p-3">{{ Object.values(NETTSKJEMA_QUESTIONS)[index] }}</td>
            <td v-for="(user, index) in users" :key="index">
              <div
                class="ml-6 w-6 h-6 bg-tl-traffic-agree rounded-full"
                :class="questionColor(question, nettskjemaId, user)"
              ></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'

import { NETTSKJEMA_QUESTIONS } from '@/constants'

import { useSurveyStore } from '@/composition/stores/surveyStore.js'
import { useTeamStore } from '@/composition/stores/teamStore.js'

enum ExperienceLegend {
  CompletelyAgree = 'CompletelyAgree',
  Agree = 'Agree',
  Neutral = 'Neutral',
  Disagree = 'Disagree',
  CompletelyDisagree = 'CompletelyDisagree'
}

interface ExperienceRating {
  color: string
  value: ExperienceLegend
}

const ratings: ExperienceRating[] = [
  { color: 'bg-tl-traffic-cagree', value: ExperienceLegend.CompletelyAgree },
  { color: 'bg-tl-traffic-agree', value: ExperienceLegend.Agree },
  { color: 'bg-tl-traffic-neutral', value: ExperienceLegend.Neutral },
  { color: 'bg-tl-traffic-disagree', value: ExperienceLegend.Disagree },
  { color: 'bg-tl-traffic-cdisagree', value: ExperienceLegend.CompletelyDisagree }
]

const surveyStore = useSurveyStore()
const teamStore = useTeamStore()

const nettskjemaIds = surveyStore.getters.selectedSurvey_ids

const surveyData = computed(() => {
  const team = teamStore.getters.selectedTeam.value ?? ''
  return surveyStore.getters.allSurveys.value.get(team)
})

const users = computed(() => {
  const users: Set<string> = new Set()
  surveyData.value?.answers.forEach((v) => {
    if (v.userName) users.add(v.userName)
  })
  return users
})

onMounted(() => {
  surveyStore.actions.selectSurveyForm()
})

const questionColor = (question: string, nettskjemaId: number, user: string) => {
  const questionData = surveyData.value?.answers.find(
    (v) =>
      v.nettskjemaId === nettskjemaId.toString() &&
      v.userName === user &&
      v.externalQuestionId === question
  )
  if (questionData) {
    switch (questionData.text?.toLowerCase().trim()) {
      case 'agree':
        return 'bg-tl-traffic-agree'
      case 'completely agree':
        return 'bg-tl-traffic-cagree'
      case 'neither agree nor disagree':
        return 'bg-tl-traffic-neutral'
      case 'disagree':
        return 'bg-tl-traffic-disagree'
      case 'completely disagree':
        return 'bg-tl-traffic-cdisagree'
    }
  }
}
</script>
