/* DIALOG */

export enum DialogMessageType {
  Information = 'Information',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error'
}

export interface DialogMessage {
  title: string
  message: string
  duration: number
  type: DialogMessageType
  timestamp: Date
  callback: () => void | null
}

export enum LoginType {
  Google = 'google',
  Feide = 'feide',
  PIN = 'pin',
  SAML = 'saml',
  TESTING = 'testing'
}

export interface AuthType {
  label: string
  type: LoginType
  url: string
  serviceName: string
  icon: string
  name: string
}
