import { createApp } from 'vue'
import { i18n } from './i18n'

import './index.css'
import './globalStyles.css'

import 'vue-multiselect/dist/vue-multiselect.css'

import App from './App.vue'
import router from './router/index.js'

import './assets/main.css'

const app = createApp(App)

// @ts-ignore
app.use(router).use(i18n)
app.mount('#app')
