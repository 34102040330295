import { DialogMessageType, type DialogMessage } from '@/interfaces.js'
import { reactive } from 'vue'

const _state = reactive({
  messages: [] as DialogMessage[],
  notifications: [] as DialogMessage[]
})

interface State {
  messages: DialogMessage[]
  notifications: DialogMessage[] // NOTE: will be used to display notifications
}

interface Actions {
  pushMessage: (
    title?: string,
    type?: DialogMessageType,
    message?: string,
    duration?: number,
    callback?: () => void
  ) => void
  pushNotification: (
    title?: string,
    type?: DialogMessageType,
    message?: string,
    duration?: number,
    callback?: () => void
  ) => void
  empty: () => void
  emptyNotifications: () => void
}

interface Getters {
  currentMessage: () => DialogMessage | undefined
  hasNewMessages: () => boolean
  currentNotification: () => DialogMessage | undefined
  hasNewNotifications: () => boolean
}

interface ServiceInterface {
  state: State
  actions: Actions
  getters: Getters
}

function useDialogStore(): ServiceInterface {
  const actions = {
    pushMessage(
      title?: string | number,
      type?: DialogMessageType,
      message?: string,
      duration?: number,
      callback?: () => void
    ) {
      const dialogMessage = {
        title: title || '',
        message: message || '',
        duration: duration || 3500,
        type: type || DialogMessageType.Success,
        timestamp: new Date(),
        callback: callback || null
      } as DialogMessage

      state.messages.push(dialogMessage)
    },

    pushNotification(
      title?: string | number,
      type?: DialogMessageType,
      message?: string,
      duration?: number,
      callback?: () => void
    ) {
      const dialogMessage = {
        title: title || '',
        message: message || '',
        duration: duration || 3500,
        type: type || DialogMessageType.Success,
        timestamp: new Date(),
        callback: callback || null
      } as DialogMessage

      state.notifications.push(dialogMessage)
    },

    empty() {
      state.messages = []
    },

    emptyNotifications() {
      state.notifications = []
    }
  }

  const getters = {
    currentMessage(): DialogMessage | undefined {
      return state.messages.pop()
    },

    hasNewMessages(): boolean {
      return state.messages.length > 0
    },

    currentNotification(): DialogMessage | undefined {
      return state.notifications.pop()
    },

    hasNewNotifications(): boolean {
      return state.notifications.length > 0
    }
  }

  const state = _state

  return { state, actions, getters }
}

export default useDialogStore
