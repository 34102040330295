<template>
  <div class="h-full" :class="{ dark: appStore.getters.darkMode.value }">
    <MenuBar />
    <transition name="fade" mode="out-in">
      <component :is="comp" :key="$route.path" />
    </transition>
    <DialogManager />
    <router-view />
  </div>
</template>

<script setup lang="ts">
import { type Component } from 'vue'
import { RouterView } from 'vue-router'

import { useAppStore } from '@/composition/stores/appStore.js'

import DialogManager from '@/components/dialog/DialogManager.vue'
import MenuBar from './views/MenuBar.vue'

const appStore = useAppStore()

let comp: Component
</script>

<style>
/* ---- Transition effects available throughout the app ---- */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
