<template>
  <div class="bg-tl-green-100 p-10 rounded-40px flex flex-row">
    <div class="flex flex-col w-1/2 mr-2">
      <TranscriptView :time="time" :trim="trim" :data="testUtterances" />
      <NetworkChart class="h-72" :actors="testActors" :links="testLinks" />
    </div>
    <VideoModule
      :session="sessionGetters.selectedSession.value?.session ?? 1"
      :group="sessionGetters.selectedGroup.value?.name ?? 'group1'"
      @timeupdate="(time: number) => (state.currentTime = time)"
    ></VideoModule>
  </div>
</template>

<script setup lang="ts">
import { useSessionStore } from '@/composition/stores/sessionStore.js'
import TranscriptView from '@/components/nursing/TranscriptView.vue'
import NetworkChart from '@/components/charts/NetworkChart.vue'
import VideoModule from '@/components/nursing/VideoModule.vue'

import { reactive, type PropType } from 'vue'
import type { TranscriptRow } from '@/components/nursing/TranscriptView.vue'

const testActors = [
  {
    id: 1,
    label: 'Student 1',
    colour: '#AA00BB'
  },
  {
    id: 2,
    label: 'Student 2',
    colour: '#0F7744'
  },
  {
    id: 3,
    label: 'Student 3',
    colour: '#8F7744'
  }
]

const testLinks = [
  {
    source: 1,
    target: 2,
    label: 'Retrieve Medicine'
  },
  {
    source: 2,
    target: 3,
    label: 'Call Doctor'
  },
  {
    source: 3,
    target: 2,
    label: 'Doctor Was Called'
  }
]

const testUtterances: TranscriptRow[] = [
  {
    speaker: 'Student 1',
    receiver: 'Student 2',
    utterance: 'I said something, did you hear?',
    code: 'Open loop',
    start: 1000,
    end: 1100
  },
  {
    speaker: 'Student 3',
    receiver: '',
    utterance: 'Blah blah blah',
    code: '',
    start: 1200,
    end: 1300
  },
  {
    speaker: 'Student 2',
    receiver: 'Student 1',
    utterance: 'I heard you',
    code: 'Close loop',
    start: 1500,
    end: 1600
  }
]

defineProps({
  time: { type: Number, required: true },
  trim: { type: Object as PropType<number[]>, required: true }
})

interface State {
  currentTime: number
}

const state: State = reactive({
  currentTime: 0
})

const { getters: sessionGetters } = useSessionStore()
</script>
