const globalTranslations = {
  no: {
    created: 'opprettet',
    dashboard: 'Dashboard',
    analytics: 'Analytics'
  },
  en: {
    created: 'created',
    dashboard: 'Dashboard',
    analytics: 'Analytics'
  }
}

export { globalTranslations }
