import { type ComputedRef, computed, reactive } from 'vue'
import { type ExtendedStoreResponse, type StoreResponse } from '@/models/main.js'
import type { APIRequestPayload, DataCSVRow, NursingGroup, TLEvent } from '@frontend'
import { EventStatus, EventType, Speaker, TabName } from '@/constants.js'
import type { Session } from '@database'
import useApiService from '@/api/apiRequest.js'
import type { Segment } from '@database'
import { ErrorCode, XHR_REQUEST_TYPE } from '@/constants.js'
import type { ModuleType } from '@models'
import { parseTLTime } from '@/utilities.js'

const apiService = useApiService()

// TODO: define some generic class / interface for that
interface Visualisation {}
type EditableSegment = Segment & { index: number }

enum FileState {
  Default = 'Default',
  Processing = 'Processing',
  Done = 'Done'
}

// TODO: outsource ProcessingState to common type repo on the server end...
interface State {
  visualisations: Visualisation[] | undefined
  fileStates: Record<string, FileState> // map of visualisation ids and their state
  groups: NursingGroup[]
  selectedGroup: NursingGroup | undefined
  selectedSession: Session | undefined
  selectedSpeaker: Speaker
  selectedTab: TabName
  csvData: DataCSVRow[]
  keyEvents: TLEvent[]
  events: {
    [key in TabName]: TLEvent[]
  }
}

const state: State = reactive({
  visualisations: undefined,
  fileStates: {},
  groups: [],
  selectedGroup: undefined,
  selectedSession: undefined,
  selectedTab: TabName.General,
  selectedSpeaker: Speaker.None,
  csvData: [],
  keyEvents: [],
  events: {
    [TabName.General]: [],
    [TabName.Communication]: [],
    [TabName.TaskCoordination]: [],
    [TabName.StressManagement]: []
  }
})

interface Actions {
  setTab: (tab: TabName) => void
  setSpeaker: (student: Speaker) => void
  setSelectedGroup: (group: NursingGroup) => void
  setSelectedSession: (session: Session) => void
  getVisualisationsForSession: (id: string) => Promise<StoreResponse>
  getGroupsForUser: () => Promise<StoreResponse>
  retriggerForSession: (id: string) => Promise<StoreResponse>
  checkFileStatus: (id: string) => Promise<ExtendedStoreResponse<FileState>>
  updateSegments: (segments: Segment[]) => Promise<StoreResponse>
  insertSegment: (
    atIndex: number,
    replace: boolean,
    segment?: EditableSegment
  ) => EditableSegment | undefined

  // New routines 2nd prototype
  getDataCSV: (group: string, session: number) => void
  setEvents: (key: TabName, eventList: TLEvent[]) => void
  createSession: (groupName: string) => Promise<StoreResponse>
  runPipeline: (sessionId: string, modules?: ModuleType[]) => Promise<StoreResponse>
}

interface Getters {
  groups: ComputedRef<NursingGroup[]>
  selectedGroup: ComputedRef<NursingGroup | undefined>
  selectedSession: ComputedRef<Session | undefined>
  selectedTab: ComputedRef<TabName>
  selectedSpeaker: ComputedRef<Speaker>
  visualisations: ComputedRef<Visualisation[] | undefined>
  fileState: ComputedRef<Record<string, FileState>>
  keyEvents: ComputedRef<TLEvent[]>
  events: ComputedRef<TLEvent[]>
}

interface ServiceInterface {
  state: State
  actions: Actions
  getters: Getters
}

function useSessionStore(): ServiceInterface {
  // Process the CSV data file to create events that will be shown on the timeline
  function createEventsFromSession() {
    if (state.selectedSession && state.selectedSession.keyEvents['Simulation_Start']) {
      state.keyEvents = []
      const session = state.selectedSession
      const startTime = parseTLTime(session.keyEvents['Simulation_Start'])
      delete session.keyEvents['Baseline_Start']
      delete session.keyEvents['Baseline_End']
      delete session.keyEvents['Simulation_Start']
      delete session.keyEvents['Simulation_End']

      const keys = Object.keys(session.keyEvents)
      keys.forEach((key, index) => {
        const event: TLEvent = {
          id: `keyevent-${index}`,
          type: EventType.Key,
          name: key.replace('_', ' '),
          speaker: '',
          receiver: '',
          utterance: '',
          start: parseTLTime(session.keyEvents[key]) - startTime,
          end: -1,
          status: EventStatus.None,
          codes: []
        }
        state.keyEvents.push(event)
      })
    }
  }

  // Process the CSV data file to create events that will be shown on the timeline
  function createEventsFromCSV() {
    const loopMap: Map<string, TLEvent> = new Map()
    const taskMap: Map<string, TLEvent> = new Map()
    let loopIndex = 1
    let taskIndex = 1
    state.csvData.forEach((row, index) => {
      const loopID = row['Loop ID'].toString()
      const taskID = row['Task ID'].toString()
      if (loopID) {
        if (loopMap.has(loopID)) {
          const event = loopMap.get(loopID)!
          event.end = parseTLTime(row.End)
          event.codes.push(row['Loop Code'])
        } else {
          const event: TLEvent = {
            id: `loopevent-${index}`,
            type: EventType.Loop,
            name: `Loop ${loopIndex}`,
            speaker: row.Speaker,
            receiver: row.Receiver,
            utterance: row.Utterance,
            start: parseTLTime(row.Start),
            end: parseTLTime(row.End),
            status: row['Loop Status'],
            codes: [row['Loop Code']]
          }
          loopMap.set(loopID, event)
          loopIndex++
        }
      } else if (taskID) {
        if (taskMap.has(taskID)) {
          const event = taskMap.get(taskID)!
          event.end = parseTLTime(row.End)
          event.codes.push(row['Task Code'])
        } else {
          const event: TLEvent = {
            id: `taskevent-${index}`,
            type: EventType.Task,
            name: `Task ${taskIndex}`,
            speaker: row.Speaker,
            receiver: row.Receiver,
            utterance: row.Utterance,
            start: parseTLTime(row.Start),
            end: parseTLTime(row.End),
            status: row['Task Status'],
            codes: [row['Task Code']]
          }
          taskMap.set(taskID, event)
          taskIndex++
        }
      }
    })
    state.events[TabName.Communication] = Array.from(loopMap.values())
    state.events[TabName.TaskCoordination] = Array.from(taskMap.values())
  }

  const actions = {
    setSelectedGroup(group: NursingGroup): void {
      state.selectedGroup = group
    },

    setSelectedSession(session: Session): void {
      state.selectedSession = session
      createEventsFromSession()
    },

    async createSession(groupName: string): Promise<StoreResponse> {
      const res = await apiService.pipeline.createSession(groupName)
      if (res.errors && res.errors.length > 0) {
        const error = 'Unable to create new session'
        console.warn(res.errors ? res.errors : error)
      } else {
        return { success: true }
      }
      return { success: false, error: res.errors }
    },

    async runPipeline(sessionId: string, modules?: ModuleType[]): Promise<StoreResponse> {
      const res = await apiService.pipeline.runPipeline(sessionId, modules)
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'Error during pipeline run'
        console.warn(res.errors ? res.errors : error)
      } else {
        return { success: true }
      }
      return { success: false, error: res.errors }
    },

    async getGroupsForUser(): Promise<StoreResponse> {
      const res = await apiService.request.getGroups()
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
      } else {
        const rows = Object.values(res.data) as NursingGroup[]
        state.groups = rows
        return { success: true }
      }
      return { success: false, error: res.errors }
    },

    async getVisualisationsForSession(): Promise<StoreResponse> {
      const res = await apiService.request.test()
      console.log(res)
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
      } else {
        console.log(res.data)
        return { success: true }
      }
      return { success: false, error: res.errors }
    },

    // re-triggers the processing of visualisations for a session
    async retriggerForSession(): Promise<StoreResponse> {
      // TODO: figure out how to trigger the correct processing? Is this mapped in the db and the server takes care of it?
      const res = await apiService.request.test()
      console.log(res)
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
      } else {
        console.log(res.data)
        return { success: true }
      }
      return { success: false, error: res.errors }
    },

    // Inserts at given index and returns the index of the inserted segment
    // If replace == true, delete the existing segment before inserting (can be used to update a segment)
    // If segment is undefined and replace == true, just delete the given index
    insertSegment(
      atIndex: number,
      replace = false,
      segment?: EditableSegment
    ): EditableSegment | undefined {
      if (state.selectedSession) {
        if (segment) {
          state.selectedSession.segments.splice(atIndex, replace ? 1 : 0, segment)
          segment.index = atIndex
          return segment
        } else {
          state.selectedSession.segments.splice(atIndex, 1)
        }
      }
    },

    async updateSegments(segments: Segment[]): Promise<StoreResponse> {
      if (state.selectedSession) {
        const res = await apiService.request.updateSegments(state.selectedSession.id, segments)
        if ((res.errors && res.errors.length > 0) || !res.data) {
          const error = 'Segment update query contains no records or invalid session'
          console.error(res.errors ? res.errors : error)
        } else {
          const segs = Object.values(res.data) as Segment[]
          state.selectedSession.segments = segs
          return { success: true }
        }
        return { success: false, error: res.errors }
      }
      return { success: false, error: new Error('No session selected') }
    },

    async checkFileStatus(): Promise<ExtendedStoreResponse<FileState>> {
      /*
      const res = await apiService.request.test()
      console.log(res)
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
      } else {
        const result = res.data as string
        const fileState: FileState = FileState[result]
        return { success: true, data: fileState }
      }
        */
      return { success: false, data: FileState.Default, error: [] }
    },

    getDataCSV: async function (group: string, session: number): Promise<void> {
      const payload: APIRequestPayload = {
        errorCode: ErrorCode.NURSING,
        method: XHR_REQUEST_TYPE.GET,
        credentials: true,
        route: `/api/file/codes?group=${group}&session=${session}`,
        convertDates: true
      }
      const res = await apiService.base.apiRequest<DataCSVRow[]>(payload)
      if (!res.error && res.data) {
        state.csvData = res.data
        createEventsFromCSV()
      }
    },
    setEvents: function (key: TabName, eventList: TLEvent[]): void {
      state.events[key] = eventList
    },
    setTab: function (tab: TabName): void {
      state.selectedTab = tab
    },
    setSpeaker: function (student: Speaker): void {
      state.selectedSpeaker = student
    }
  }

  const getters = {
    get groups(): ComputedRef<NursingGroup[]> {
      return computed(() => state.groups)
    },
    get selectedGroup(): ComputedRef<NursingGroup | undefined> {
      return computed(() => state.selectedGroup)
    },
    get selectedSession(): ComputedRef<Session | undefined> {
      return computed(() => state.selectedSession)
    },
    get selectedTab(): ComputedRef<TabName> {
      return computed(() => state.selectedTab)
    },
    get selectedSpeaker(): ComputedRef<Speaker> {
      return computed(() => state.selectedSpeaker)
    },
    get visualisations(): ComputedRef<Visualisation[] | undefined> {
      return computed(() => state.visualisations)
    },
    get fileState(): ComputedRef<Record<string, FileState>> {
      return computed(() => state.fileStates)
    },
    get events(): ComputedRef<TLEvent[]> {
      return computed(() => state.events[state.selectedTab])
    },
    get keyEvents(): ComputedRef<TLEvent[]> {
      return computed(() => state.keyEvents)
    }
  }

  return { state, actions, getters }
}

export { useSessionStore }
