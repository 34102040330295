/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

import { HttpException } from '@frontend'
import type { APIResponse, CmsGQLQuery } from '@frontend'
import useApiService from '@/api/apiRequest.js'

import {
  cmsUrl,
  cmsClientId,
  cmsClientSecret,
  cmsTokenUrl,
  LanguageFallbacks,
  LanguageCodes,
  XHR_REQUEST_TYPE,
  XHR_CONTENT_TYPE,
  ErrorCode
} from '@/constants.js'

interface TokenResponse {
  access_token: string
  expires_in: number
  token_type: string
  scope: string
}

const apiService = useApiService()

async function fetchToken(): Promise<string> {
  if (cmsUrl.includes('localhost')) return Promise.resolve('')

  const response = await apiService.base.apiRequest<TokenResponse>({
    errorCode: ErrorCode.SQUIDEX,
    route: '',
    method: XHR_REQUEST_TYPE.POST, // or 'PUT'
    credentials: false,
    contentType: XHR_CONTENT_TYPE.URLENCODED,
    body: `grant_type=client_credentials&client_id=${cmsClientId}&client_secret=${cmsClientSecret}&scope=squidex-api`,
    baseURL: `${cmsTokenUrl}`
  })
  return response.data?.access_token || ''
}

function cmsRequest(
  projectName: string,
  query: string,
  replacables: Record<string, string | number>,
  language: LanguageCodes
): Promise<CmsGQLQuery | undefined> {
  return new Promise((resolve) => {
    function checkForError(error: HttpException) {
      if (error.status === 401) {
        console.log('Unauthorized. Attempting to fetch new token ...' + error.message)
        fetchToken().then((newToken) => {
          localStorage.setItem('squidex-token', newToken)
          resolve(cmsRequest(projectName, query, variables, language))
        })
      } else console.log(new Error('CMS request error: ' + error.message))
    }

    const token = localStorage.getItem('squidex-token')
    const fallbacks = LanguageFallbacks[language].reduce((prev, curr) => prev + ',' + curr, '')
    // Squidex does not support standard a GraphQL query body!
    // const body = JSON.stringify({ query, variables }),
    Object.keys(replacables).forEach((key: string) => {
      const regex = new RegExp(key, 'g')
      const v = replacables[key].toString()
      query = query.replace(regex, v)
    })

    const variables = {}
    const body = { query, variables } // NOTE: MUST use the name 'query' here
    apiService.base
      .apiRequest<CmsGQLQuery>({
        errorCode: ErrorCode.SQUIDEX,
        route: `/api/content/${projectName}/graphql`,
        method: XHR_REQUEST_TYPE.POST,
        credentials: false,
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Languages': `${language}${fallbacks}`
        },
        body,
        contentType: XHR_CONTENT_TYPE.JSON,
        baseURL: `${cmsUrl}`
      })
      .then((res: APIResponse<CmsGQLQuery>) => {
        if (res.error) {
          checkForError(new HttpException(res.error.status, res.error.message))
        } else if (res.data?.errors && res.data.errors.length > 0) {
          // Squidex returns errors in res.errors
          const e = res.data.errors[0]
          if (e) checkForError(new HttpException(404, e.message))
        }
        resolve(res.data)
      })
      .catch((error: HttpException) => {
        console.log(error)
        checkForError(error)
      })
  })
}

export { cmsRequest, fetchToken }
