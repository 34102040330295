<template>
  <div class="w-full full-height flex items-center justify-center flex-col bg-gray-800">
    <div class="bg-gray-400 rounded-xl p-5 flex justify-center items-center">
      <div class="p-3 rounded-xl">
        <h1 class="text-2xl text-white font-bold">{{ t('login') }}</h1>
        <div
          v-for="(button, index) in authMethods"
          :key="index"
          class="flex flex-row justify-start items-center bg-white m-2 p-2 rounded-md cursor-pointer"
          @click="login(button)"
        >
          <img :key="index" :src="button.icon" :alt="button.name" class="mx-2 p-1 h-11" />
          <div class="text-xl text-black">
            {{ button.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Arrow from '@/assets/icons/angle-left-solid.svg'
import Feide from '@/assets/icons/feide-icon.svg'
import UsersIcon from '@/assets/icons/svg/users.svg'
import { serverBaseUrl } from '@/constants'
import { LoginType, type AuthType } from '@/interfaces'

const messages = {
  no: {
    anonymous: 'Delta med PIN',
    missingpin: 'Vennligst oppgi en PIN kode før du fortsetter',
    wrongpin: 'Vennligst velg en annen PIN og prøv igjen'
  },
  en: {
    anonymous: 'Join with PIN',
    missingpin: 'Please enter a PIN code before you proceed',
    wrongpin: 'Please choose another PIN and try again'
  }
}

const { t } = useI18n({ messages })

const authMethods = [
  {
    label: 'FEIDE',
    type: LoginType.Feide,
    url: `${serverBaseUrl}/auth/${LoginType.Feide}`,
    serviceName: 'feide',
    icon: Feide,
    name: 'Feide icon'
  },
  {
    label: 'Testing',
    type: LoginType.TESTING,
    url: `${serverBaseUrl}/auth/${LoginType.TESTING}`,
    serviceName: 'testing',
    icon: UsersIcon,
    name: 'Users icon'
  }
] as AuthType[]

function redirectToAuth(authType: AuthType) {
  window.location.href = authType.url
}

async function login(authType: AuthType) {
  redirectToAuth(authType)
}
</script>

<style scoped></style>
