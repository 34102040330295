/* This store is used to handle the data fetch from nettskjema */

import { computed, reactive, ref, type ComputedRef, type Ref } from 'vue'
import useApiService from '@/api/apiRequest.js'
import { useTeamStore } from '@/composition/stores/teamStore.js'
import { ErrorCode, XHR_REQUEST_TYPE } from '@/constants.js'
import type { IAnswer, ISubmission } from '@teams'
import type { IAggRes } from '@/models/legal.js'
const { getters: teamGetters } = useTeamStore()

export interface SurveyResponseData {
  studentsResponse: Record<string, unknown>
  teachersResponse: Record<string, unknown>
}

interface State {
  //selectedSurvey_id: number | undefined
  selectedSurvey_ids: number[] | undefined
  surveyResults: IAnswer[]
  allSurveys: Map<string, ISubmission>
  aggRes: IAggRes[]
}

const state: State = reactive({
  selectedSurvey_ids: [],
  surveyResults: [],
  allSurveys: new Map(),
  aggRes: []
})
// ------------  Internal functions ------------

const apiService = useApiService()

// ------------  Getters --------------

// Once a reactive getter has been gotten by a component
// we cannot overwrite its instance here in the store - but we can write to its children reactively
// Complex objects provided by a getter here should be represented by a Class and also have an update() function
interface Getters {
  selectedSurvey_ids: ComputedRef<number[] | undefined>
  surveyResults: ComputedRef<IAnswer[]>
  allSurveys: ComputedRef<Map<string, ISubmission>>
  aggRes: ComputedRef<IAggRes[]>
}
const getters = {
  get selectedSurvey_ids(): ComputedRef<number[] | undefined> {
    return computed(() => state.selectedSurvey_ids) // This is the 'currently selected' Team
  },
  get surveyResults(): ComputedRef<IAnswer[]> {
    return computed(() => state.surveyResults) // This is the 'currently selected' Team
  },
  get allSurveys(): ComputedRef<Map<string, ISubmission>> {
    return computed(() => state.allSurveys) // This is the 'currently selected' Team
  },
  get aggRes(): ComputedRef<IAggRes[]> {
    return computed(() => state.aggRes)
  }
}

interface Actions {
  selectSurveyForm: () => Promise<void>
}
const actions = {
  selectSurveyForm: async function (): Promise<void> {
    state.surveyResults = []
    const team_id = teamGetters.selectedTeam.value || ''
    state.selectedSurvey_ids = [427150, 400391]
    const response = await apiService.base.apiRequest<ISubmission[]>({
      errorCode: ErrorCode.SURVEY,
      route: '/api/survey',
      method: XHR_REQUEST_TYPE.GET,
      query: { ids: state.selectedSurvey_ids.join(',') }
    })

    if (response.data) {
      response.data.map((item: ISubmission) => {
        state.allSurveys.set(item.groupId, item)
      })
    }

    state.allSurveys.get(team_id)?.answers.map((ans: IAnswer) => {
      state.surveyResults.push(ans)
    })

    const aggRes = state.allSurveys.get(team_id)?.aggAnswers

    if (aggRes) {
      state.aggRes = []
      for (const [key, value] of Object.entries(aggRes)) {
        const temResObj: IAggRes = {
          externalQuestionId: '',
          mean: 0
        }
        temResObj.externalQuestionId = key.substring(1)
        temResObj.mean = value.mean as number
        console.log('External Question ID ', temResObj.externalQuestionId, ' Mean ', temResObj.mean)
        state.aggRes.push(temResObj)
      }
    }

    return Promise.resolve()
  }
}

interface ServiceInterface {
  actions: Actions
  getters: Getters
}
// This defines the interface used externally
export function useSurveyStore(): ServiceInterface {
  return {
    getters,
    actions
  }
}

export type useSurveyStore = ReturnType<typeof useSurveyStore>
