<template>
  <div class="pt-8">
    <div class="mb-4 bg-black rounded-40px" v-for="(document, index) in documentsInfo" :key="index">
      <div class="row flex-row px-16 mb-8 py-8 border-b-2 border-white/20 rounded-t-20px">
        <!-- Document Name -->
        <div class="flex flex-row text-white font-normal text-2xl">
          {{ document.name }}
        </div>

        <!-- Created Date -->
        <div class="flex flex-row text-white/50 text-sm">Created {{ document.date }}</div>
      </div>
      <div class="row flex-row px-16 pb-8">
        <!-- Document Summary -->
        <div class="flex flex-row">
          <div class="flex flex-col">
            <!--Document Icon-->
            <img class="h-7" :src="DocumentIcon" />
          </div>
          <div class="flex flex-col text-white font-semibold text-lg ml-2 my-auto">
            Document Summary
          </div>
        </div>

        <div
          class="flex flex-row text-white font-normal overflow-hidden py-5"
          :class="{ 'max-h-10': toggleText[index] }"
        >
          <!--{{ version.Feedback }}-->
          {{ text }}
        </div>

        <!-- Read More/Hide Button -->
        <div class="flex flex-row mt-1">
          <div class="flex flex-col">
            <button
              id="toggle-btn"
              class="text-purple-300 font-semibold focus:outline-none text-decoration-line: underline py-5"
              @click="toggleText[index] = !toggleText[index]"
            >
              {{ toggleText[index] ? 'View More' : 'Hide' }}
            </button>
          </div>
        </div>
        <!-- ChatGPT Feedback -->
        <div class="flex flex-row mt-4 mb-4">
          <div class="flex flex-col">
            <!--Document Icon-->
            <img class="h-7" :src="ChatGPTFeebackIcon" />
          </div>
          <div class="flex flex-col text-white font-semibold text-lg ml-2 my-auto">
            ChatGPT Assessment
          </div>
        </div>

        <div class="flex flex-row text-white font-normal pb-8">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useTeamStore } from '@/composition/stores/teamStore.js'
import DocumentIcon from '@/assets/icons/svg/documents.svg'
import ChatGPTFeebackIcon from '@/assets/icons/svg/gptfeedback.svg'

const teamStore = useTeamStore()
const documents = teamStore.getters.documentMetaDataAnalytics

const documentsInfo = computed(() =>
  documents.value.versionInfo.map((m) => ({
    name: m.fileName,
    date: new Date(m.createdDate).toLocaleDateString('default', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  }))
)
const toggleText = ref<Array<boolean>>(documents.value.versionInfo.map(() => true))
const text = `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id est laborum.
`
</script>
