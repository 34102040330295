<template>
  <div class="flex flex-col m-11">
    <div class="flex">
      <div class="flex mr-1.5">
        <div class="flex bg-tl-grey-200 px-4 py-3 rounded-xl">
          <img
            v-if="teams.length > 1"
            :class="[showTeamsDropdown ? 'rotate-180' : '']"
            class="w-4 invert mr-3"
            :src="ArrowIcon"
          />
          <p class="text-white text-xl" @click="toggleTeamDropdown()">
            {{ teamStore.getters.selectedTeam.value || 'Demo Team' }}
          </p>
          <div
            class="absolute -ml-4 -mt-3 z-50 bg-tl-grey-200 rounded-xl text-white text-xl"
            v-if="showTeamsDropdown"
          >
            <div
              class="cursor-pointer hover:font-bold hover:bg-tl-grey-300 px-4 py-3"
              v-for="(team, index) in teams"
              :key="index"
              @click="selectTeam(team)"
            >
              <p>{{ team }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex bg-tl-grey-200 px-4 py-3 rounded-xl cursor-pointer">
          <img
            v-if="true"
            :class="[showTypesDropdown ? 'rotate-180' : '']"
            class="w-4 invert mr-3"
            :src="ArrowIcon"
          />
          <p class="text-white text-xl" @click="toggleTypeDropdown()">
            {{ selectedAnalytics }}
          </p>
          <div
            class="absolute -ml-4 -mt-3 z-50 bg-tl-grey-200 rounded-xl text-white text-xl"
            v-if="showTypesDropdown"
          >
            <div
              class="cursor-pointer hover:font-bold hover:bg-tl-grey-300 px-8 py-3"
              v-for="(type, index) in Object.values(AnalyticsType)"
              :key="index"
              @click="selectAnalytics(type)"
            >
              <p>{{ type }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="meetingTabs"
      class="w-full flex flex-row p-2 my-4"
      v-if="route.name !== 'WorkDocuments'"
    >
      <div
        v-for="mTab of meetingTabs"
        :key="mTab.id"
        class="rounded-full p-2.5 px-3.5 mr-2 last:mr-0 text-sm uppercase font-semibold cursor-pointer"
        :class="activeClass(mTab.date)"
        @click="selectMeeting(mTab.id)"
      >
        {{ (mTab.name + mTab.date).toUpperCase() }}
      </div>
    </div>
    <div>{{ selectedTab }}</div>
    <!-- Tab Content -->
    <router-view :selected-tab="selectedTab" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, type Ref } from 'vue'
import router from '@/router'
import { useRoute } from 'vue-router'

import { useTeamStore } from '@/composition/stores/teamStore'

import ArrowIcon from '@/assets/icons/fontawesome/arrow-down-regular.svg'
import { AnalyticsType } from '@/constants'
import { useMeetingStore } from '@/composition/stores/meetingStore'

const teamStore = useTeamStore()
const meetingStore = useMeetingStore()
const route = useRoute()

const teams = teamStore.getters.allTeams
const meetings = meetingStore.getters.speakingInfo

const meetingTabs = computed(() =>
  meetings.value.map((m) => ({
    id: m.filename,
    name: m.filename.slice(0, m.filename.indexOf('GRP')).replaceAll('_', ' ') + '- ',
    date: new Date(m.createdDate).toLocaleDateString('default', { month: 'long', year: 'numeric' })
  }))
)

const selectedAnalytics: Ref<AnalyticsType> = ref(AnalyticsType.CollaborationExperience)
const showTeamsDropdown: Ref<boolean> = ref(false)
const showTypesDropdown: Ref<boolean> = ref(false)
const selectedTab: Ref<string> = ref('')

onMounted(() => {
  if (route.name) {
    switch (route.name) {
      case 'MeetingAnalytics':
        selectedAnalytics.value = AnalyticsType.MeetingAnalytics
        break
      case 'WorkDocuments':
        selectedAnalytics.value = AnalyticsType.WorkDocuments
        break
      default:
        selectedAnalytics.value = AnalyticsType.CollaborationExperience
    }
  }
  selectTeam('') // restore default values from sessionStorage if reloaded
})

function selectMeeting(meetingId: string) {
  selectedTab.value = meetingId
}

function toggleTeamDropdown() {
  const teacher = true
  if (teacher) {
    showTeamsDropdown.value = !showTeamsDropdown.value
  }
}

function toggleTypeDropdown() {
  showTypesDropdown.value = !showTypesDropdown.value
}

function selectTeam(teamId: string) {
  teamStore.actions.selectTeam(teamId)
  showTeamsDropdown.value = false
}

function selectAnalytics(type: AnalyticsType) {
  selectedAnalytics.value = type
  router.push({ name: type })
  toggleTypeDropdown()
}

console.log(route.name)

const activeClass = (tabName: string) =>
  selectedTab.value === tabName ? 'bg-white text-tl-grey-100' : 'bg-tl-grey-100 text-white'
</script>
